import type { TableProps } from "antd"
import { message } from "antd"
import type { ColumnsType, FilterValue } from "antd/es/table/interface"
import axios from "axios"
import _ from "lodash"
import { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Socket } from "socket.io-client"
import { AuthContext } from "../../components/auth-context"
import { PairsBrokersListContext } from "../../components/used-pairs-brokers-context"
import { pairList } from "../../constants"
import { getPairFromId } from "../../helpers"
import { OrderBlotterDeal, OrderBlotterParsedDeal } from "../../types"
import { useLocalStorage } from "../../utils/useLocalStorage"
import {
  filterAndPrependIncomingDeals,
  getColumnSearchProps,
  getFilteredOrderBlotterData,
  transformSentDateTime,
} from "./helpers"
import {
  BuySellText,
  CopyIconStyled,
  ExtendedId,
  HeaderButtonWrapper,
  OrderBlotterTitle,
  OrderBlotterWrapper,
  QuoteIconStyled,
  SizeText,
  StyledButton,
  StyledPauseButton,
  StyledTable,
} from "./styled"

let cache = {} as Record<string, string>
let bufferOrders: OrderBlotterDeal[] = []

export const OrderBlotter = ({ socket }: { socket: Socket | undefined }) => {
  const [dataSource, setDataSource] = useState<OrderBlotterParsedDeal[]>([])
  const [cursor, setCursor] = useState<{ id: number; eldestDate: number }>({
    id: -1,
    eldestDate: Infinity,
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { tradedPairs } = useContext(PairsBrokersListContext)
  const { brokersList } = useContext(PairsBrokersListContext)
  const { getCurrentUser } = useContext(AuthContext)
  const {
    storage: savedFilters,
    updateStorage: updateSavedFilters,
    deleteStorage: deleteSavedFilters,
  } = useLocalStorage<Record<string, FilterValue | null>>(
    "order-blotter-filters",
    {},
  )
  const [pauseUpdate, setPauseUpdate] = useState<boolean>(false)
  const tradedPairsId = useMemo(
    () => tradedPairs.map(({ id }) => id),
    [tradedPairs],
  )

  const url = useMemo(
    () =>
      (process.env.REACT_APP_ENDPOINT || "http://localhost:4000") +
      "/order-blotter/",
    [],
  )

  const router = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const key = "updatable"

  const getOrderData = useCallback(
    (orderId: string) => {
      getCurrentUser().then(user => {
        if (!user.isLogged) return
        if (Object.keys(cache).includes(orderId)) {
          navigator.clipboard.writeText(cache[orderId])
          messageApi.open({
            key,
            type: "info",
            content: "Order data available in clipboard!",
            duration: 3,
          })
          return
        }
        messageApi.open({
          key,
          type: "loading",
          content: "Loading data for order_id " + orderId + "...",
          duration: 100, //longer duration will be replaced by the success or error new message
        })
        axios
          .get(url + "aggregator/order-data", {
            params: {
              orderId,
            },
            headers: {
              Authorization: user.tokens.token,
            },
          })
          .then(res => {
            cache = {
              ...cache,
              [orderId]: res.data,
            }
            navigator.clipboard.writeText(res.data)
            messageApi.open({
              key,
              type: "success",
              content: "Order data available in clipboard!",
              duration: 3,
            })
          })
          .catch(err => {
            console.error("err->", err)
            messageApi.open({
              key,
              type: "error",
              content: "Something went wrong.",
              duration: 3,
            })
          })
      })
    },
    [getCurrentUser, messageApi, url],
  )

  const getQuotesData = useCallback(
    (datetime: string, pair: string) => {
      getCurrentUser().then(user => {
        if (!user.isLogged) return
        const cacheKey = `${datetime}-${pair}`
        if (Object.keys(cache).includes(cacheKey)) {
          navigator.clipboard.writeText(cache[cacheKey])
          messageApi.open({
            key,
            type: "info",
            content: "Quotes data available in clipboard!",
            duration: 3,
          })
          return
        }
        messageApi.open({
          key,
          type: "loading",
          content:
            "Loading quotes data for " + datetime + " and pair " + pair + "...",
          duration: 100, //longer duration will be replaced by the success or error new message
        })
        axios
          .get(url + "aggregator/quotes", {
            params: {
              datetime,
              pair,
            },
            headers: {
              Authorization: user.tokens.token,
            },
          })
          .then(res => {
            cache = {
              ...cache,
              [cacheKey]: res.data,
            }
            navigator.clipboard.writeText(res.data)
            messageApi.open({
              key,
              type: "success",
              content: "Quotes data available in clipboard!",
              duration: 3,
            })
          })
          .catch(err => {
            console.error("err->", err)
            messageApi.open({
              key,
              type: "error",
              content: "Something went wrong.",
              duration: 3,
            })
          })
      })
    },
    [getCurrentUser, messageApi, url],
  )

  const handleFilterChange: TableProps<OrderBlotterParsedDeal>["onChange"] = (
    _pagination,
    filters,
    _sorter,
  ) => {
    updateSavedFilters(filters)
  }

  const clearFilters = () => {
    deleteSavedFilters()
  }

  const changePauseStatus = () => {
    if (pauseUpdate) {
      //potentially we loose some data while resuming
      setDataSource(dataSource =>
        bufferOrders.reduce(
          (data, deal) =>
            filterAndPrependIncomingDeals(deal, data, savedFilters),
          dataSource,
        ),
      )
      bufferOrders = []
    }
    setPauseUpdate(!pauseUpdate)
  }

  const handlePageChange = useCallback(
    async (page: number, pageSize: number) => {
      const totPages = Math.ceil(dataSource.length / pageSize)
      if (page !== totPages) {
        setIsLoading(false)
        return
      } else setIsLoading(true)
      const user = await getCurrentUser()
      if (!user.isLogged) {
        router("/login")
        return
      }

      const parsedDeals = await getFilteredOrderBlotterData(
        user.tokens.token,
        savedFilters,
        cursor,
      )
      if (parsedDeals.length > 0) {
        const newCursorDeal = _.minBy(parsedDeals, p => p.id) || {
          saved_at: Infinity,
          id: -1,
        }
        setCursor({
          eldestDate: newCursorDeal.saved_at ?? Infinity,
          id: newCursorDeal.id ?? -1,
        })
        setDataSource(oldVal => [...oldVal, ...parsedDeals])
      }
      setIsLoading(false)
    },
    [cursor, dataSource.length, savedFilters, getCurrentUser, router],
  )

  useEffect(() => {
    setIsLoading(true)
    getCurrentUser().then(user => {
      if (user.isLogged)
        getFilteredOrderBlotterData(user.tokens.token, savedFilters).then(x => {
          if (x.length > 0) {
            const newCursorDeal = _.minBy(x, p => p.id) || {
              saved_at: Infinity,
              id: -1,
            }
            setCursor({
              eldestDate: newCursorDeal.saved_at ?? Infinity,
              id: newCursorDeal.id ?? -1,
            })
            setDataSource(x)
          }
          setIsLoading(false)
        })
    })
  }, [getCurrentUser, router, savedFilters])

  useEffect(() => {
    if (!socket) return
    socket.emit("subscribe", "orderBlotter-aggregated-all")
    return () => {
      socket.emit("unsubscribe", "orderBlotter-aggregated-all")
    }
  }, [socket])

  useEffect(() => {
    if (!socket) return
    socket?.on("orderBlotter", order => {
      if (pauseUpdate) bufferOrders = [...bufferOrders, order.data]
      else
        setDataSource(dataSource =>
          filterAndPrependIncomingDeals(order.data, dataSource, savedFilters),
        )
    })
    return () => {
      socket.removeListener("orderBlotter")
    }
  }, [savedFilters, socket, pauseUpdate])

  const columns = useMemo<ColumnsType<OrderBlotterParsedDeal>>(
    () => [
      {
        title: "Sent time",
        dataIndex: "sent_time",
        filteredValue: null,
        render: (_t, record, _i) => (
          <ExtendedId>
            <div>{record.sent_time}</div>
            <QuoteIconStyled
              title={`get quotes logs for ${record.sent_time}, pair ${record.pair} and interval 1s`}
              onClick={() => {
                const dateTime = transformSentDateTime(record.sent_time)
                const pair = record.pair.toLowerCase()
                getQuotesData(dateTime, pair)
              }}
            />
          </ExtendedId>
        ),
      },
      {
        title: "Deal ID",
        dataIndex: "deal_id",
        ...getColumnSearchProps("deal_id", savedFilters),
        filteredValue: savedFilters.deal_id || null,
      },
      {
        title: "Internal ID",
        dataIndex: "internal_id",
        filteredValue: savedFilters.internal_id || null,
        ...getColumnSearchProps("internal_id", savedFilters, [
          { text: "Resubmitted", value: "resubmittedDeals" },
          { text: "Manual", value: "manualDeals" },
          { text: "Fake", value: "fakeDeals" },
        ]),
        render: (_t, record, _i) => (
          <ExtendedId>
            <div>{record.internal_id}</div>
            <CopyIconStyled
              title={`Get order_id logs for ${record.internal_id}`}
              onClick={() => {
                getOrderData(record.internal_id)
              }}
            />
          </ExtendedId>
        ),
      },
      {
        title: "Pair",
        dataIndex: "pair",
        filters: (tradedPairsId || pairList).map(p => ({
          text: getPairFromId(p).label,
          value: getPairFromId(p).label,
        })),
        filteredValue: savedFilters.pair || null,
        onFilter: (value, record) => record.pair === value,
      },
      {
        title: "Buy/Sell",
        dataIndex: "operation",
        filters: ["Buy", "Sell"].map(x => ({
          text: x,
          value: x,
        })),
        onFilter: (value, record) => record.operation === value,
        filteredValue: savedFilters.operation || null,
        render: (_t, record, _i) => (
          <BuySellText operation={record.operation} />
        ),
      },
      {
        title: "Direction",
        dataIndex: "direction",
        filteredValue: null,
      },
      {
        title: "Size",
        dataIndex: "size",
        render: (t, record, _i) => (
          <SizeText operation={record.operation}>
            {(record.operation === "Sell" ? "-" : "") + t.toLocaleString("en")}
          </SizeText>
        ),
        filteredValue: null,
        align: "right",
      },
      {
        title: "Base ccy",
        dataIndex: "currency",
        filteredValue: null,
      },
      {
        title: "Rate",
        dataIndex: "rate",
        filteredValue: null,
      },
      {
        title: "Broker",
        dataIndex: "broker",
        filters: brokersList
          .filter(b => !b.isPOP)
          .map(b => ({
            text: b.id,
            value: b.id,
          })),
        filteredValue: savedFilters.broker || null,
        onFilter: (value, record) => record.broker === value,
        filterSearch: true,
      },
      {
        title: "Delay (ms)",
        dataIndex: "delay_ms",
        filteredValue: null,
      },
    ],
    [tradedPairsId, savedFilters, brokersList, getOrderData, getQuotesData],
  )

  return (
    <OrderBlotterWrapper>
      {contextHolder}
      <OrderBlotterTitle>Order list</OrderBlotterTitle>
      <HeaderButtonWrapper>
        <StyledPauseButton
          paused={pauseUpdate ? "true" : "false"}
          onClick={changePauseStatus}
          type="primary"
          title={(pauseUpdate ? "Resume" : "Pause") + " the update of the list"}
        >
          {pauseUpdate ? "Resume" : "Pause"}
        </StyledPauseButton>
        <StyledButton onClick={clearFilters} type="primary">
          Reset Filters
        </StyledButton>
      </HeaderButtonWrapper>
      <StyledTable
        columns={columns}
        dataSource={dataSource}
        pagination={{
          defaultPageSize: 50,
          onChange: handlePageChange,
          size: "default",
        }}
        loading={isLoading}
        onChange={handleFilterChange}
        rowKey="deal_id"
        getPopupContainer={trigger => {
          return trigger
        }}
        size="small"
      />
    </OrderBlotterWrapper>
  )
}
