import _ from "lodash"
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { graphsMenuItems } from "../../constants"
import { getGraphOptions } from "../../helpers"
import { GraphOptions, GraphsList, GraphType } from "../../types"
import CustomTooltip from "../tooltip"
import { PairsBrokersListContext } from "../used-pairs-brokers-context"
import { addGraphsWithOnlyAggregatedOptions } from "./helpers"
import {
  AddWidgetButton,
  AddWidgetButtonWrapper,
  AddWidgetMenuWrapperModalGrid,
  AddWidgetModalStyled,
  MenuItemLabel,
  MenuItemWrapper,
} from "./styled"

const onlyAggregatedGraphsOptions = {
  brokers: {
    aggregated: true,
  },
  pairs: {
    aggregated: true,
  },
}

const ButtonAddWidget: React.FC<{
  className?: string
  graphs: GraphsList
  setGraphs: React.Dispatch<React.SetStateAction<GraphsList>>
}> = ({ className = "add-widget-button", graphs, setGraphs }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [openModal, setOpenModal] = useState<GraphType | false>(false)
  const [graphOptions, setGraphOptions] = useState<GraphOptions>()

  useEffect(() => {
    if (!openModal) {
      setGraphOptions(undefined)
      return
    }
    const newGraphOptions = getGraphOptions(openModal)
    if (!newGraphOptions) {
      setGraphOptions(undefined)
      return
    }
    setGraphOptions(newGraphOptions)
  }, [openModal])

  const buttonRef = useRef<SVGSVGElement>(null)
  const { brokersList } = useContext(PairsBrokersListContext)

  const tooManyGraphsOfSameType = useMemo(
    () =>
      graphsMenuItems.reduce(
        (acc, item) => ({
          ...acc,
          [item.graph]: item.maxItems
            ? graphs.filter(g => g.type === item.graph).length >= item.maxItems
            : false,
        }),
        {},
      ) as Record<GraphType, boolean>,
    [graphs],
  )

  const clickListener = useCallback((event: MouseEvent) => {
    if (
      buttonRef.current &&
      buttonRef.current.contains(event.target as HTMLElement)
    )
      return
    setIsOpen(false)
  }, [])

  useEffect(() => {
    window.addEventListener("click", clickListener)
    return () => window.removeEventListener("click", clickListener)
  }, [clickListener])

  return (
    <>
      <AddWidgetButtonWrapper className={className}>
        {isOpen ? (
          <AddWidgetMenuWrapperModalGrid>
            {graphsMenuItems.map((entry, index) => (
              <CustomTooltip
                show={tooManyGraphsOfSameType?.[entry.graph]}
                graphType={entry.graph}
                key={index}
              >
                <MenuItemWrapper
                  key={`item-${entry}-menu-${index}`}
                  isFirst={index === 0}
                  onClick={() => {
                    if (tooManyGraphsOfSameType?.[entry.graph]) return
                    if (
                      _.isEqual(
                        getGraphOptions(entry.graph),
                        onlyAggregatedGraphsOptions,
                      )
                    ) {
                      addGraphsWithOnlyAggregatedOptions(
                        setGraphs,
                        brokersList,
                        entry.graph,
                      )
                      return
                    }
                    setOpenModal(entry.graph)
                  }}
                  disabled={tooManyGraphsOfSameType?.[entry.graph]}
                >
                  <entry.icon />
                  <MenuItemLabel className="MenuItemLabel">
                    {entry.label}
                  </MenuItemLabel>
                </MenuItemWrapper>
              </CustomTooltip>
            ))}
          </AddWidgetMenuWrapperModalGrid>
        ) : (
          <></>
        )}
        <AddWidgetButton
          onClick={_ => setIsOpen(!isOpen)}
          $isMenuOpen={isOpen}
          ref={buttonRef}
          title="Add widget"
        />
      </AddWidgetButtonWrapper>
      {openModal && graphOptions && (
        <AddWidgetModalStyled
          graphType={openModal}
          graphs={graphs}
          setGraphs={setGraphs}
          openModal={openModal}
          setOpenModal={setOpenModal}
          options={graphOptions}
        />
      )}
    </>
  )
}

export default ButtonAddWidget
