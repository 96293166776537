import React, { useCallback, useEffect, useMemo, useState } from "react"
import { getBaseCurrency, getBrokerPair } from "../../helpers"
import {
  CryptoComparisonData,
  CryptoComparisonTick,
  GraphInfo,
  GraphReactComponent,
} from "../../types"
import WidgetHeader from "../widget-header"
import { WidgetSubheader } from "../widget-subheader"
import { WidgetWrapper } from "../widget-wrapper"
import { Colored, GridWrapper, Header, Label, PairLabel } from "./styled"
import { pairList } from "../../constants"
import { formatUSD } from "./helpers"

const columnLabels = [
  "PAIR",
  "VOLUME (BASE)",
  "VOLUME (USD)",
  "EXPOSURE (BASE)",
  "EXPOSURE (USD)",
]

const CryptoComparison: GraphReactComponent = ({
  socket,
  broker,
  pair,
  graphs,
  setGraphs,
  id,
  vwap,
  comm,
}) => {
  const [cryptoComparisonData, setCryptoComparisonData] = useState<
    CryptoComparisonData[]
  >([])

  const cryptoComparisonListener = useCallback(
    (tick: CryptoComparisonTick) => {
      const brokerPair = getBrokerPair(broker, pair)
      if (tick.brokerPair === brokerPair)
        setCryptoComparisonData(
          tick.data.sort((a, b) => (a.pair > b.pair ? +1 : -1)),
        )
    },
    [broker, pair],
  )

  useEffect(() => {
    if (!socket) return () => {}
    socket.on("cryptoComparison", cryptoComparisonListener)

    return () => socket.off("cryptoComparison", cryptoComparisonListener)
  }, [socket, graphs, cryptoComparisonListener])

  const graphInfo: GraphInfo = useMemo(
    () => ({
      broker,
      pair,
      type: "cryptoComparison",
      id,
      Graph: CryptoComparison,
      vwap,
      comm,
    }),
    [broker, comm, id, pair, vwap],
  )

  return (
    <WidgetWrapper>
      <WidgetHeader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
        widgetTitle="Crypto Comparisons"
      />
      <WidgetSubheader
        graphs={graphs}
        setGraphs={setGraphs}
        graphInfo={graphInfo}
      />
      <GridWrapper>
        {columnLabels.map((label, idx) => (
          <Header key={`h-${idx}`}>{label}</Header>
        ))}
        {cryptoComparisonData.map(
          ({
            pair,
            totalVolumeBaseCurrency,
            totalVolumeUSD,
            exposureBaseCurrency,
            exposureUSD,
          }) => {
            const isSizeZero =
              exposureBaseCurrency < 0.001 && exposureBaseCurrency > -0.001
            const pairLabel = pairList.find(p => p.id === pair)?.label || ""
            const pairUnit = getBaseCurrency(pair).toUpperCase()
            return (
              <React.Fragment key={`row-${pair}`}>
                <PairLabel key={`label-${pair}`}>{`${pairLabel}`}</PairLabel>
                <Label
                  key={`vol-bc-${pair}`}
                >{`${totalVolumeBaseCurrency.toFixed(2)}${pairUnit}`}</Label>
                <Label key={`vol-usd-${pair}`}>
                  {formatUSD(totalVolumeUSD)}
                </Label>
                <Colored
                  key={`exp-bc-${pair}`}
                  isNegative={exposureBaseCurrency < 0}
                  isSizeZero={isSizeZero}
                >{`${exposureBaseCurrency.toFixed(2)}${pairUnit}`}</Colored>
                <Colored
                  key={`exp-usd-${pair}`}
                  isNegative={exposureUSD < 0}
                  isSizeZero={isSizeZero}
                >
                  {formatUSD(exposureUSD)}
                </Colored>
              </React.Fragment>
            )
          },
        )}
      </GridWrapper>
    </WidgetWrapper>
  )
}

export default CryptoComparison
