import { CurrencyUppercase } from "../../types"

const formatRateFX = (rate: number, currency: CurrencyUppercase) =>
  rate.toLocaleString("en", {
    minimumFractionDigits: currency === "JPY" ? 3 : 5,
    maximumFractionDigits: currency === "JPY" ? 3 : 5,
  })

const formatRateCrypto = (rate: number, _currency: CurrencyUppercase) =>
  rate.toLocaleString("en", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

export const formatRate =
  process.env.REACT_APP_INSTANCE_TYPE === "crypto"
    ? formatRateCrypto
    : formatRateFX

const formatExposureFX = (amount: number, currency: CurrencyUppercase) =>
  amount.toLocaleString("en", {
    style: "currency",
    currency: currency,
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    currencyDisplay: "narrowSymbol",
  })

export const formatExposureCrypto = (
  amount: number,
  currency: CurrencyUppercase,
) =>
  amount.toLocaleString("en", {
    style: "currency",
    currency: currency,
    maximumFractionDigits: currency === "BTC" ? 2 : 0,
    minimumFractionDigits: currency === "BTC" ? 2 : 0,
    currencyDisplay: "narrowSymbol",
  })

export const formatExposure =
  process.env.REACT_APP_INSTANCE_TYPE === "crypto"
    ? formatExposureCrypto
    : formatExposureFX
