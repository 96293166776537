import { useContext, useEffect } from "react"
import { AuthContext } from "../../components/auth-context"
import SettingsColorsCard from "../../components/settings-card-colors"
import SettingsManualExposureCard from "../../components/settings-card-manual-exposure"
import SettingsMarginsCard from "../../components/settings-card-margins"
import SettingsPenalizationsCard from "../../components/settings-card-penalizations"
import SettingsResetCard from "../../components/settings-card-reset"
import SettingsSlippageCard from "../../components/settings-card-slippage"
import SettingsStartingBalanceCard from "../../components/settings-card-starting-balance"
import { SettingsCardsWrapper } from "./styled"
import SettingsEnableBrokersCard from "../../components/settings-card-enable-brokers"
import SettingsCommissionsCard from "../../components/settings-card-commissions"

export const Settings = () => {
  const { getCurrentUser } = useContext(AuthContext)

  useEffect(() => {
    getCurrentUser().then(user => {
      if (!user.isLogged) return
    })
  }, [getCurrentUser])

  if (process.env.REACT_APP_INSTANCE_TYPE === "crypto")
    return (
      <SettingsCardsWrapper>
        <SettingsStartingBalanceCard />
        <SettingsMarginsCard />
        <SettingsColorsCard />
        <SettingsEnableBrokersCard />
        <SettingsCommissionsCard />
      </SettingsCardsWrapper>
    )

  return (
    <SettingsCardsWrapper>
      <SettingsStartingBalanceCard />
      <SettingsMarginsCard />
      <SettingsColorsCard />
      <SettingsPenalizationsCard />
      <SettingsResetCard />
      <SettingsManualExposureCard />
      <SettingsSlippageCard />
    </SettingsCardsWrapper>
  )
}
