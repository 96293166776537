export const formatExposure = (label: string) =>
  process.env.REACT_APP_INSTANCE_TYPE === "crypto"
    ? Number(label).toFixed(2).toString() + "BTC"
    : "$" + Number(label).toFixed(1).toString() + "m"

export const tooltipPointFormat =
  '<div style="line-height: 12px;white-space: nowrap;color:#e9e9ec;">' +
  '<span style="color: {series.color};">&#11044</span>' +
  (process.env.REACT_APP_INSTANCE_TYPE === "crypto"
    ? '<span style="color:#e9e9ec;"> {series.name}: <b>{point.y}BTC</b></span>'
    : '<span style="color:#e9e9ec;"> {series.name}: $<b>{point.y}m</b></span>') +
  "</div><br />"
